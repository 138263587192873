import * as React from "react";
import "../fonts/fonts.css";
import "./styles.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import DATA from "../data";
import { navigate } from "gatsby"

const IndexPage = () => {
  return (
    <main id="home" onClick={() => navigate("/portfolio")}>
      <title>{DATA.title}</title>
  
      <Header />
        <div className="content">
          <a href="portfolio" className="hello">
            {DATA.homepage.hello}
          </a>
          <div
            className="caption"
            dangerouslySetInnerHTML={{ __html: DATA.homepage.caption }}
          ></div>
        </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
